import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact/Contact";
import Layout from "./components/Layout/Layout";
import About from "./pages/About/About";
import Auth from "./pages/Auth/Auth";
import { Provider } from "react-redux";
import store from "./state/store";
import UserProfile from "./pages/UserProfile/UserProfile";
import BookingConfirmation from "./pages/BookingConfirmation/BookingConfirmation";
import VehicleList from "./components/VehicleList/VehicleList";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" index element={<App />} />
            <Route path="/about" index element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/confirmation" element={<BookingConfirmation />} />
            <Route path="/listing" element={<VehicleList />} />
            <Route path="/login" element={<Auth />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <RouterProvider router={router} /> */}
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
