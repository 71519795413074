import React, { useState } from "react";
import "./BookingConfirmation.scss";

import axios from "axios";
import {
  FaCar,
  FaMapMarkerAlt,
  FaDollarSign,
  FaClock,
  FaCheckCircle,
  FaTimesCircle,
  FaUserTie,
  FaShieldAlt,
} from "react-icons/fa";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FAQComponent from "../../components/FAQComponent/FAQComponent";
import Loader from "../../components/Loader/Loader";

const BookingDetails = ({
  pickupLocation,
  dropLocation,
  pickupTime,
  vehicle,
  pricing,
  bookingType,
}) => (
  <div className="bkDetails">
    <div className="bkDetails-type">Trip Type: {bookingType}</div>

    <div className="bkDetails-card">
      <FaMapMarkerAlt className="bkDetails-card-icon" />
      <div className="bkDetails-card-details">
        <div className="bkDetails-card-details-title">Pickup Location</div>
        <div className="bkDetails-card-details-location">{pickupLocation}</div>
      </div>
    </div>
    {dropLocation ? (
      <div className="bkDetails-card">
        <FaMapMarkerAlt className="bkDetails-card-icon" />
        <div className="bkDetails-card-details">
          <div className="bkDetails-card-details-title">Drop Location</div>
          <div className="bkDetails-card-details-location">{dropLocation}</div>
        </div>
      </div>
    ) : (
      ""
    )}
    <div className="bkDetails-card">
      <FaClock className="bkDetails-card-icon" />
      <div className="bkDetails-card-details">
        <div className="bkDetails-card-details-title">Pickup Time</div>
        <div className="bkDetails-card-details-location">{pickupTime}</div>
      </div>
    </div>
    <div className="bkDetails-card">
      <FaCar className="bkDetails-card-icon" />
      <div className="bkDetails-card-details">
        <div className="bkDetails-card-details-title">Vehicle</div>
        <div className="bkDetails-card-details-location">{vehicle}</div>
      </div>
    </div>
    <div className="bkDetails-card">
      <FaDollarSign className="bkDetails-card-icon" />
      <div className="bkDetails-card-details">
        <div className="bkDetails-card-details-title">Estimated Price</div>
        <div className="bkDetails-card-details-location">
          {pricing ? pricing : "Based on KMs"}
        </div>
      </div>
    </div>
  </div>
);

const VehicleCarousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const goToPrevSlide = () => {
    let index = activeIndex;
    let imagesLength = images.length;
    if (index < 1) {
      index = imagesLength;
    }
    --index;
    setActiveIndex(index);
  };

  const goToNextSlide = () => {
    let index = activeIndex;
    let imagesLength = images.length - 1;
    if (index === imagesLength) {
      index = -1;
    }
    ++index;
    setActiveIndex(index);
  };

  return (
    <div className="carousel-container">
      <button className="carousel-left-arrow" onClick={goToPrevSlide}>
        &lt;
      </button>
      <div className="carousel-slide">
        <img src={images[activeIndex]} alt={`Vehicle`} />
      </div>
      <button className="carousel-right-arrow" onClick={goToNextSlide}>
        &gt;
      </button>
    </div>
  );
};

const VehicleDetails = ({ details }) => (
  <div className="vehicle-details">
    <h2>Vehicle Details</h2>
    {Object.entries(details).map(([key, value]) => (
      <p key={key}>
        <strong>{key}:</strong> {value}
      </p>
    ))}
  </div>
);

const AdditionalInfo = ({ terms, cancellationPolicy }) => (
  <div className="addinfo">
    <div className="addinfo-terms">{terms}</div>
    <div className="addinfo-policy">{cancellationPolicy}</div>
  </div>
);

const ConfirmationButton = ({ onConfirm }) => (
  <div className="confirmation-button">
    <button onClick={onConfirm}>Confirm Booking</button>
  </div>
);

const Inclusions = ({ items }) => (
  <div className="incs">
    <h3>Inclusions</h3>
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <FaCheckCircle className="incs-icon" /> {item}
        </li>
      ))}
    </ul>
  </div>
);

const Exclusions = ({ items }) => (
  <div className="excs">
    <h3>Exclusions</h3>
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <FaTimesCircle className="excs-icon" /> {item}
        </li>
      ))}
    </ul>
  </div>
);

const BookingConfirmation = () => {
  const [isLoading, setisLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const booking = useSelector((state) => state.booking);

  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateTimeString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  // Replace these with actual dynamic values from your application's state or props
  const bookingDetails = {
    pickupLocation: booking.pickupLocation,
    dropLocation: booking.dropLocation,
    // pickupTime: booking.dateTime,
    pickupTime: formatDateTime(booking.dateTime),
    vehicle: booking.vname,
    type: booking.vtype,
    capacity: booking.vcapacity,
    pricing: booking.price,
    bookingType: booking.activeTab,
  };

  console.log("Search images ", booking);

  const vehicleImages = booking.images.map((img) => img.image_url);

  const terms =
    "Please ensure to verify your booking details before confirming. Terms and conditions apply.";
  const cancellationPolicy = "Free cancellation within 24 hours of booking.";

  const handleConfirm = async () => {
    setisLoading(true);

    const authTokens = JSON.parse(localStorage.getItem("authTokens"));
    if (!authTokens) {
      alert("Please login to continue!");
      navigate("/login");
      return;
    }

    try {
      // const response = await axios.post(
      //   "http://127.0.0.1:8000/api/create_booking/",
      //   bookingData
      // );
      // console.log("Booking successful", response.data);

      const access_token = authTokens.access;
      const refresh_token = authTokens.refresh;

      const config = {
        headers: { Authorization: `Bearer ${access_token}` },
      };

      axios
        .post("https://api.buswing.com/api/create_booking/", booking, config)
        // .post("http://127.0.0.1:8000//api/create_booking/", booking, config)
        .then((response) => {
          setisLoading(false);
          navigate("/profile");
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 401) {
            console.log("Invalid token detected. Refreshing token...");

            // Refresh access token
            axios
              .post("https://api.buswing.com/api/token/refresh/", {
                refresh: refresh_token,
              }) // Replace with your backend endpoint
              .then((response) => {
                localStorage.setItem(
                  "authTokens",
                  JSON.stringify(response.data)
                );
                setUserData(response.data.user);

                // Retry get user profile with new token
                axios
                  .post(
                    "https://api.buswing.com/api/create_booking/",
                    booking,
                    {
                      headers: {
                        Authorization: `Bearer ${response.data.access}`,
                      },
                    }
                  )
                  .then((response) => {
                    setisLoading(false);
                    navigate("/profile");
                  })
                  .catch((error) => {
                    console.error(
                      "Failed to fetch user profile with new token",
                      error
                    );
                    alert("Something went wrong");
                    setisLoading(false);
                  });
              })
              .catch((error) => {
                console.error("Failed to refresh token", error);
                setisLoading(false);
                navigate("/login");
                alert("Please login again to continue");
              });
          }
        });
    } catch (error) {
      setisLoading(false);
      alert("Something went wrong, please try again");
      console.error("Error creating booking", error);
      // handle error
    }
  };

  const inclusionItems = [
    "Total range of KMs that the vehicle is booked for.",
    "Timely Pickup and Drop Service.",
    "Free wifi within the cab throughout the journey.",
    "GPS Navigation to the selected destination.",
  ];
  const exclusionItems = [
    "Toll Charges are not included in the price.",
    "Any Parking Fees is to be paid by the customer.",
    "Late Return Fee (if applicable) is to be paid by the customer.",
    "For Buses - Extra Permit and State Tax is applicable.",
  ];
  const driverInfo = { name: "John Doe", experience: 5 };
  const safetyTips = [
    "Regularly sanitized vehicle",
    "First Aid Kit available",
    "Breakdown assistance",
  ];

  const backtoSearch = () => {
    navigate("/");
  };

  return (
    <div className="conf">
      {isLoading && <Loader />}
      <div className="conf-back" onClick={backtoSearch}>
        <span>&#x2190;</span> Back to Search{" "}
      </div>
      <div className="conf-content">
        <div className="conf-content-left">
          <div className="conf-content-left-vehicle">
            <div className="conf-content-left-vehicle-name">
              {bookingDetails.vehicle}.
            </div>{" "}
            <span>or any similar model.</span>
            <div className="conf-content-left-vehicle-type">
              {bookingDetails.type} | {bookingDetails.capacity} Seater
            </div>
          </div>
          <VehicleCarousel images={vehicleImages} />
        </div>

        <div className="conf-content-right">
          <BookingDetails {...bookingDetails} />
          <AdditionalInfo
            terms={terms}
            cancellationPolicy={cancellationPolicy}
          />
          <ConfirmationButton onConfirm={handleConfirm} />
        </div>
      </div>
      <div className="conf-limits">
        <div className="conf-limits-inc">
          <Inclusions items={inclusionItems} />
        </div>
        <div className="conf-limits-exc">
          <Exclusions items={exclusionItems} />
        </div>
      </div>
      <div className="conf-extras">
        <div className="conf-extras-ddetails">
          <FaUserTie className="conf-extras-ddetails-icon" />
          <h3 className="conf-extras-ddetails-title">Driver Details</h3>
          <div className="conf-extras-ddetails-desc">
            You will receive the driver and vehicle details half an hour before
            the journey.
          </div>
        </div>

        <div className="conf-extras-safety">
          <h3>Safety Pointers</h3>
          {safetyTips.map((pointer, index) => (
            <p key={index}>
              <FaShieldAlt className="icon" /> {pointer}
            </p>
          ))}
        </div>
      </div>
      <div className="conf-faqs">
        <FAQComponent />
      </div>

      <div className="conf-bottom"></div>
    </div>
  );
};

export default BookingConfirmation;
