import "./Header.scss";
import { useSelector } from "react-redux";
import React from "react";
import Logo from "../../assets/images/lancehawksTravels.png";
import userImg from "../../assets/images/user.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as Burger } from "../../assets/images/Burger.svg";
import Button from "../Button/Button";

function Header({ onClick }) {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.name);

  return (
    <header className="header Poppins">
      <div className="header-logo" onClick={() => navigate("/")}>
        <img src={Logo} alt="Buswing" />
      </div>
      <div className="header-links">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "activeLink header-links-link" : "header-links-link"
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive ? "activeLink header-links-link" : "header-links-link"
          }
        >
          About Us
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive ? "activeLink header-links-link" : "header-links-link"
          }
        >
          Contact Us
        </NavLink>
      </div>
      <div className="header-buttons">
        <div className="header-buttons-lg">
          {user ? (
            <img
              src={userImg}
              alt="User image"
              onClick={() => {
                navigate("/profile");
              }}
            />
          ) : (
            <Button
              onClick={() => {
                navigate("/login");
              }}
              type={`primary`}
              text="Login"
            />
          )}
        </div>
        <div href="" className="header-buttons-burger">
          <Burger onClick={onClick} />
        </div>
      </div>
    </header>
  );
}

export default Header;
