import { createSlice } from "@reduxjs/toolkit";

export const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    activeTab: "",
    pickupLocation: "",
    dropLocation: "",
    pickupLatLng: null,
    dropLatLng: null,
    distance: null,
    dateTime: null,
    numPeople: 1,
    roundtrip: false,
    returnDateTime: null,
    packageSelected: null,

    vid: null,
    vname: null,
    vtype: null,
    vcapacity: 0,
    vbase: 0,
    vkm: 0,
    price: 0,
    images: [],
  },
  reducers: {
    setBookingDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetBookingDetails: (state) => {
      state.pickupLocation = "";
      state.dropLocation = "";
      state.pickupLatLng = null;
      state.dropLatLng = null;
      state.distance = null;
      state.dateTime = null;
      state.numPeople = 1;
      state.roundtrip = false;
      state.returnDateTime = null;
      state.packageSelected = null;

      state.vid = null;
      state.vname = null;
      state.vtype = null;
      state.vcapacity = 0;
      state.vbase = 0;
      state.vkm = 0;
      state.price = 0;
      state.images = [];
      // Reset additional fields if necessary
    },
  },
});

export const { setBookingDetails, resetBookingDetails } = bookingSlice.actions;

export default bookingSlice.reducer;
