// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import bookingReducer from "./booking/bookingSlice";
import formReducer from "./booking/formSlice";
import vehiclesReducer from "./booking/vehicleSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    booking: bookingReducer,
    form: formReducer,
    vehicle: vehiclesReducer,
    // Add other reducers if needed
  },
});

export default store;
