import "./Experience.scss";

import React from "react";
import experiencebg from "../../assets/images/experience.png";
import { ReactComponent as MaintainedCar } from "../../assets/images/maintainedCar.svg";
import { ReactComponent as Driver } from "../../assets/images/driver.svg";
import { ReactComponent as TimelyPickup } from "../../assets/images/timelyPickup.svg";
import { ReactComponent as CustomerSupport } from "../../assets/images/customerSupport.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Experience() {
  // Settings for the slider
  const settings = {
    dots: false, // Show dot indicators
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div
      className="experience section"
      style={{ backgroundImage: `url(${experiencebg})` }}
    >
      <div className="experience-title">Experience the luxury of Buswing</div>
      <div className="experience-content">
        <Slider {...settings}>
          <ExperienceCard
            svg={<MaintainedCar />}
            text="Well maintained vehicles"
          />
          <ExperienceCard svg={<Driver />} text="Professional drivers" />
          <ExperienceCard
            svg={<TimelyPickup />}
            text="Timely pickup and drop"
          />
          <ExperienceCard
            svg={<CustomerSupport />}
            text="24/7 customer support"
          />
        </Slider>
      </div>
    </div>
  );
}

export default Experience;

const ExperienceCard = ({ svg, text }) => {
  return (
    <div className="experienceCard">
      <div className="experienceCard-icon">{svg}</div>
      <div className="experienceCard-text">{text}</div>
    </div>
  );
};
