import "./ServiceCard.scss";

import React from "react";

function ServiceCard({ name, info, image }) {
  return (
    <div className="serviceCard">
      <div className="serviceCard-icon">{image}</div>
      <div className="serviceCard-name">{name}</div>
      <div className="serviceCard-info">{info}</div>
    </div>
  );
}

export default ServiceCard;
