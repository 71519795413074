import "./Services.scss";

import { ReactComponent as Outstation } from "../../assets/images/outstation.svg";
import { ReactComponent as HourlyRental } from "../../assets/images/hourlyRental.svg";
import { ReactComponent as Airport } from "../../assets/images/airport.svg";

import React from "react";
import ServiceCard from "../../components/ServiceCard/ServiceCard.jsx";
import Slider from "react-slick";

const ServicesData = [
  {
    id: 1,
    name: "Outstation",
    info: "With Buswing, travelling outstation is now very easy and luxurious. Just book your vehicle from us and enjoy your trip.",
    image: <Outstation />,
  },
  {
    id: 2,
    name: "Hourly Rental",
    info: "Don’t commit, just ride. With Buswing’s hourly rental vehicles you can book according to your hourly needs.",
    image: <HourlyRental />,
  },
  {
    id: 3,
    name: "Airport Transfer",
    info: "Flying on the wings? Leave the airport transfer hassle to BusWING. We will take you and bring you from airport on time.",
    image: <Airport />,
  },
];

function Services() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="services section section-right">
      <div className="services-border section-border-right"></div>
      <div className="services-title section-title">Our Services</div>
      <div className="services-contents">
        <Slider {...settings}>
          {ServicesData.map((item) => (
            <ServiceCard key={item.id} {...item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Services;
