import "./Steps.scss";

import { ReactComponent as Step1 } from "../../assets/images/step1.svg";
import { ReactComponent as Step2 } from "../../assets/images/step2.svg";
import { ReactComponent as Step3 } from "../../assets/images/step3.svg";

import React from "react";
import StepCard from "../../components/StepCard/StepCard.jsx";
import Slider from "react-slick";

const stepsData = [
  {
    id: 1,
    info: "Enter your details, like pick up location, number of people, date & time, etc.",
    image: <Step1 />,
  },
  {
    id: 2,
    info: "Select a vehicle from our long list of vehicles.",
    image: <Step2 />,
  },
  {
    id: 3,
    info: "Your selected vehicle will reach the pick up location at the selected date and time.",
    image: <Step3 />,
  },
];

function Steps() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="steps section section-left">
      <div className="steps-border section-border-left"></div>
      <div className="steps-title section-title">
        BOOK VEHICLE IN 3 EASY STEPS
      </div>
      <div className="steps-contents">
        <Slider {...settings}>
          {stepsData.map((item) => (
            <StepCard key={item.id} {...item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Steps;
