import { createSlice } from "@reduxjs/toolkit";

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: {
    distance: 0,
    vehicles: null,
  },
  reducers: {
    setVehicleDetails: (state, action) => {
      console.log("Payload is ", action.payload);
      const { vehicles, distance } = action.payload;
      state.distance = distance;
      state.vehicles = vehicles;
    },
  },
});

export const { setVehicleDetails } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
