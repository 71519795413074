import "./VehicleCard.scss";

import React, { useEffect, useState } from "react";
import Button from "../Button/Button";

import { useDispatch, useSelector } from "react-redux";
import { setBookingDetails } from "../../state/booking/bookingSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { jwtDecode } from "jwt-decode";

function VehicleCard({ vehicle, distance }) {
  const [isLoading, setisLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [price, setPrice] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const history = useHistory();

  const booking = useSelector((state) => state.booking);
  const activeTab = booking.activeTab;
  console.log("Booking data is ", booking);
  const packageSelected = useSelector((state) => state.booking.packageSelected);
  const roundtrip = useSelector((state) => state.booking.roundtrip);
  const pickupLocation = useSelector((state) => state.booking.pickupLocation);
  const dropLocation = useSelector((state) => state.booking.dropLocation);
  // console.log("Booking data is", booking);

  useEffect(() => {
    if (activeTab === "Outstation") {
      if (distance > vehicle.minKm) {
        const extradistance = Number(distance) - Number(vehicle.minKm);
        let cost =
          Number(vehicle.basePriceLocal) +
          Number(vehicle.driver_allowance) +
          Number(vehicle.priceKm) * Number(extradistance);

        if (roundtrip) {
          const startdate = new Date(booking.dateTime);
          const enddate = new Date(booking.returnDateTime);

          // Calculate the difference in time and convert to days
          const timeDiff = enddate - startdate;
          const numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days

          if (numberOfDays > 1) {
            // If the roundtrip spans more than one day, adjust the cost
            cost +=
              300 * vehicle.priceKm * numberOfDays +
              vehicle.driver_allowance * numberOfDays;
          }

          setPrice(Math.round(Number(cost)));
        } else {
          setPrice(Math.round(Number(cost)));
        }
      } else {
        let cost =
          Number(vehicle.driver_allowance) +
          Number(vehicle.priceKm) * Number(distance);

        if (roundtrip) {
          const startdate = new Date(booking.dateTime);
          const enddate = new Date(booking.returnDateTime);

          // Calculate the difference in time and convert to days
          const timeDiff = enddate - startdate;
          const numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // Convert milliseconds to days
          console.log(numberOfDays);

          // If the roundtrip spans more than one day, adjust the cost
          cost =
            300 * vehicle.priceKm * numberOfDays +
            vehicle.driver_allowance * numberOfDays;

          setPrice(Math.round(Number(cost)));
        } else {
          setPrice(Math.round(Number(cost)));
        }
      }
    } else if (activeTab === "Hourly Rental") {
      const packageKey = `cost${packageSelected}hrs`; // Dynamically form the key for package cost
      const packageCost = vehicle[packageKey]; // Access the cost using bracket notation
      if (packageCost) {
        setPrice(packageCost); // Set the price if the package cost is available
      } else {
        console.error(
          "Selected package cost is not defined in the vehicle object."
        );
        // Handle error or set a default/fallback price if necessary
      }
    } else if (activeTab === "Airport Transfer") {
      if (distance < 30) {
        setPrice(Number(vehicle.basePriceAirport + vehicle.driver_allowance));
      } else {
        const cost =
          (distance - 30) * vehicle.priceKm + vehicle.basePriceAirport;

        console.log("Cost is ", cost, vehicle.name);
        setPrice(Number(cost + vehicle.driver_allowance));
        console.log("Setting to ", Number(cost + vehicle.driver_allowance));
      }

      // const cost = vehicle.priceKm * distance + vehicle.driver_allowance;
      // cost > vehicle.basePriceAirport
      //   ? setPrice(Number(cost))
      //   : setPrice(Number(vehicle.basePriceAirport));
    }
    const userData = localStorage.getItem("authTokens");
    if (userData) {
      const obj = jwtDecode(JSON.parse(userData).access);
      const { phone } = obj;
      setPhoneNumber(phone);
    }
  }, [packageSelected, roundtrip, pickupLocation, dropLocation]);

  //extract phone number from access token.

  const handleSubmit = () => {
    setisLoading(true);
    // Dispatch the booking details to the Redux store
    dispatch(
      setBookingDetails({
        vid: vehicle.id,
        vname: vehicle.name,
        vtype: vehicle.type,
        vcapacity: vehicle.capacity,
        vbase: vehicle.basePrice,
        vkm: vehicle.priceKm,
        images: vehicle.images,
        price: price,
        phone: phoneNumber,
      })
    );
    // Redirect to the BookingConfirmation page
    setisLoading(false);
    navigate("/confirmation"); // For react-router v6, use navigate('/booking-confirmation')
  };

  return (
    <div className="vc">
      {isLoading && <Loader />}
      <div className="vc-info">
        {distance} KMs at Rs {vehicle.priceKm}/km.
      </div>
      <div className="vc-img">
        {/* <img src={vehicle.image} alt={`Vehicle Image`} /> */}
        <img src={vehicle.master_image} alt={`Vehicle Image`} />
      </div>
      <div className="vc-meta">
        <div className="vc-meta-name">{vehicle.name}</div>
        <span className="vc-meta-type">{vehicle.type} | </span>
        <span className="vc-meta-capacity">{vehicle.capacity} Seater</span>
      </div>
      <div className="vc-features">
        {vehicle.features.map((feature) => (
          <div className="vc-features-feature">{feature.name}</div>
        ))}
      </div>
      <div className="vc-footer">
        <div className="vc-footer-pricing">
          <div className="vc-footer-pricing-price">Rs {price}\-</div>
          {activeTab === "Outstation" && roundtrip && (
            <div className="vc-footer-pricing-info">Minimum 300kms/day.</div>
          )}
          {activeTab === "Outstation" && roundtrip && (
            <div className="vc-footer-pricing-info">
              Extra kms at Rs {vehicle.priceKm}/km.
            </div>
          )}
          {/* {price ? (
          ) : (
            <div className="vc-footer-pricing-price">
              Rs {vehicle.priceKm}/km
            </div>
          )} */}
        </div>
        <div className="vc-footer-cta">
          <Button text={`Book Now`} type={`primary`} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default VehicleCard;
