import { Outlet, Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import userImg from "../../assets/images/user.jpg";

import "./Layout.scss";

import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";

import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as Cross } from "../../assets/images/Cross.svg";

import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../state/auth/authSlice";

import { jwtDecode } from "jwt-decode";
import Button from "../Button/Button";

const { innerWidth: width } = window;

const MenuVariant = {
  initial: { x: width, scaleX: 0 },
  animate: { x: 0, scaleX: 1, transition: { duration: 0.4 } },
  exit: { x: width, transition: { duration: 0.4 } },
};

function Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("authTokens");

    if (userData) {
      const obj = jwtDecode(JSON.parse(userData).access);

      const { name, user_id } = obj;

      const user = {
        name: name,
        user_id: user_id,
      };

      dispatch(setUser(user));
    }
  }, []);

  const [clicked, setClicked] = useState(false);

  const handleMenu = () => {
    setClicked(!clicked);
  };

  return (
    <div className="layout">
      <AnimatePresence>
        {clicked ? <Menu handleMenu={handleMenu} /> : ""}
      </AnimatePresence>
      <Header onClick={handleMenu} />
      {/* <DummyHeader /> */}
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;

function Menu({ handleMenu }) {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.name);
  console.log("User is ", user);

  return (
    <motion.div
      className="menu"
      key="loader"
      variants={MenuVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="menu-header">
        <div className="menu-header-content">
          <div className="menu-header-content-letter">B</div>
          <div className="menu-header-content-letter">U</div>
          <div className="menu-header-content-letter">S</div>
          <div className="menu-header-content-letter">W</div>
          <div className="menu-header-content-letter">I</div>
          <div className="menu-header-content-letter">N</div>
          <div className="menu-header-content-letter">G</div>
        </div>
      </div>
      <div className="menu-contents">
        <motion.div className="menu-contents-cross" onClick={handleMenu}>
          <Cross />
        </motion.div>

        <div className="menu-contents-links">
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            Home
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/about");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            About Us
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            onClick={() => {
              navigate("/contact");
              handleMenu();
            }}
            className="menu-contents-links-link"
          >
            Contact Us
          </motion.div>
        </div>
        {user ? (
          // <img
          //   src={userImg}
          //   alt="User image"
          //   onClick={() => {
          //     navigate("/profile");
          //   }}
          // />
          <Button
            onClick={() => {
              navigate("/profile");
              handleMenu();
            }}
            type={`primary`}
            text="Profile"
          />
        ) : (
          <Button
            onClick={() => {
              navigate("/login");
              handleMenu();
            }}
            type={`primary`}
            text="Login"
          />
        )}
        {/* <div
          className="menu-contents-login"
          onClick={() => {
            navigate("/login");
            handleMenu();
          }}
        >
          Login
        </div> */}
      </div>
    </motion.div>
  );
}

//created to fill in the space for Header, as it is gived position: fixed.
function DummyHeader() {
  return <div className="dummyheader"></div>;
}
