import "./Contact.scss";

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import contactusbg from "../../assets/images/contactusbg.jpg";
import linkedin from "../../assets/images/linkedIn.png";
import instagram from "../../assets/images/instagram.png";
import whatsapp from "../../assets/images/whatsapp.png";
import phone from "../../assets/images/phone.png";
// import Connect from "../../sections/Connect/Connect";

const contactData = [
  {
    title: "Phone",
    value: "+91 706 101 4622",
    image: phone,
    link: "tel:+917061014622",
  },
  {
    title: "WhatsApp",
    value: "+91 7061014622",
    image: whatsapp,
    link: "https://wa.me/+917061014622",
  },
  {
    title: "Linkedin",
    value: "/buswing",
    image: linkedin,
    link: "https://www.linkedin.com/company/buswing/",
  },
  {
    title: "Instagram",
    value: "/buswing",
    image: instagram,
    link: "https://www.instagram.com/buswing_official/",
  },
];
function Contact() {
  return (
    <div
      className="contactus"
      style={{ backgroundImage: `url(${contactusbg})` }}
    >
      <div className="contactus-contents">
        <div className="contactus-contents-left">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
            className="contactus-contents-left-title"
          >
            Contact Us
          </motion.div>
          <motion.hr
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            viewport={{ once: true }}
            className="contactus-contents-left-line"
          />
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            viewport={{ once: true }}
            className="contactus-contents-left-desc"
          >
            Planning a vacation? Need a ride to the airport? Need a vehicle for
            a few hours? Or planning for some event? We are here to help you.
            Contact us and we will get back to you as soon as possible.
          </motion.div>
        </div>
        <div className="contactus-contents-right">
          <div className="contactus-contents-right-cards">
            {contactData.map((ele, index) => (
              <ContactUsCard {...ele} key={index} index={index} />
            ))}
          </div>
          <div className="contactus-contents-right-email">
            <Link to={`mailto:contact@buswing.com`}>contact@buswing.com</Link>
          </div>
        </div>
      </div>
      {/* <Connect /> */}
      {/* <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        viewport={{ once: true }}
        motion
        className="contactus-bottom"
      >
        <img src={contactmen} alt="Icons8 icon of men at work" />
      </motion.div> */}
    </div>
  );
}

export default Contact;

function ContactUsCard({ image, title, value, link, index }) {
  const diversion = index % 2 === 0 ? 0 : 30;
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: diversion }}
      transition={{ duration: 0.4, delay: 0.4 + 0.3 * index }}
      viewport={{ once: true }}
      className="contactuscard"
    >
      <Link to={link}>
        <div className="contactuscard-image">
          <img src={image} alt="Phone Number of buswing" />
        </div>
        <div className="contactuscard-title">{title}</div>
        <div className="contactuscard-value">{value}</div>
      </Link>
    </motion.div>
  );
}
