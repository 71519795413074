import "./Footer.scss";

import React from "react";

import { Link } from "react-router-dom";

//import logoIcon from "../../assets/images/logoIcon.svg";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        {/* <img
          className="footer-left-image"
          src={logoIcon}
          alt="Lancehawks Logo"
        /> */}
        <div className="footer-left-name">Buswing</div>
      </div>

      <div className="footer-seperator"></div>

      <div className="footer-middle">
        <div className="footer-middle-heading">Follow us on</div>
        <div className="footer-middle-links">
          <Link
            target="_blank"
            to={`https://www.facebook.com/profile.php?id=61551530821264`}
            className="footer-middle-links-link"
          >
            <img src={facebook} alt="Buswing Linkedin" />
            <span>facebook.com/Buswing</span>
          </Link>
          <Link
            target="_blank"
            to={`https://www.instagram.com/buswing_official/`}
            className="footer-middle-links-link"
          >
            <img src={instagram} alt="Buswing Instagram" />
            <span>instagram.com/Buswing</span>
          </Link>
        </div>
      </div>

      <div className="footer-seperator"></div>

      <div className="footer-right">
        <div className="footer-right-heading">Contact us at</div>
        <div className="footer-right-links">
          <Link
            target="_blank"
            to={`mailto:contact@lancehawks.com`}
            className="footer-right-links-link"
          >
            <img src={email} alt="Buswing Linkedin" />
            <span>contact@buswing.com</span>
          </Link>
          <Link
            target="_blank"
            to={`tel:+917061014622`}
            className="footer-right-links-link"
          >
            <img src={phone} alt="Buswing Instagram" />
            <span>+91 70610 14622</span>
          </Link>
        </div>
      </div>
      <div className="footer-footer">
        <div className="footer-footer-copyright">
          &#169; Copyright 2023 Buswing.
        </div>
        <div className="footer-footer-desc">
          Designed and Developed by{" "}
          <Link target="_blank" to={`https://lancehawks.com/`}>
            {" "}
            Lancehawks
          </Link>
          .
        </div>
      </div>
    </div>
  );
}

export default Footer;
