import "./BookingBox.scss";

// import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import React, { useEffect, useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import axios from "axios";
import Button from "../Button/Button";

import { useDispatch, useSelector } from "react-redux";
import { setFormDetails } from "../../state/booking/formSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  setBookingDetails,
  resetBookingDetails,
} from "../../state/booking/bookingSlice";
import { setVehicleDetails } from "../../state/booking/vehicleSlice";
import Loader from "../Loader/Loader";

// const BE_URL = "http://127.0.0.1:8000";
const BE_URL = "https://api.buswing.com";

function BookingBox({ setVehicleData }) {
  const [isLoading, setisLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Outstation");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [pickupLatLng, setPickupLatLng] = useState(null);
  const [dropLatLng, setDropLatLng] = useState(null);
  const [distance, setDistance] = useState(null);
  const [roundtrip, setRoundTrip] = useState(false);
  const [dateTime, setDateTime] = useState(null);
  const [dateTimeString, setDateTimeString] = useState(null);
  const [returnDateTime, setreturnDateTime] = useState(new Date());
  const [returnDateTimeString, setreturnDateTimeString] = useState(new Date());
  const [numPeople, setNumPeople] = useState(null);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const libraries = ["places"];

  const [packageSelection, setPackageSelection] = useState(null);

  const [airportTransferDirection, setAirportTransferDirection] =
    useState("to");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector((state) => state.booking);
  const vehicles = useSelector((state) => state.vehicles);
  const dateTimes = useSelector((state) => state.form.dateTime);

  useEffect(() => {
    // const dateTimeString = dateTimes.toISOString().slice(0, 16);

    setPickupLocation(form.pickupLocation);
    setDropLocation(form.dropLocation);
    setPickupLatLng(form.pickupLatLng);
    setDropLatLng(form.dropLatLng);
    setDistance(form.distance);
    setDateTime(form.dateTime ? form.dateTime : null);
    setDateTimeString(form.dateTime);
    setreturnDateTimeString(form.returnDateTime);
    // setDateTimeString(formatLocalDateTime(form.dateTime));
    // setreturnDateTimeString(formatLocalDateTime(form.returnDateTime));
    setNumPeople(form.numPeople);
    setIsRoundTrip(form.roundtrip);
    setreturnDateTime(form.returnDateTime);
    setActiveTab(form.activeTab ? form.activeTab : "Outstation");
    setPackageSelection(form.packageSelected);
    // Update other fields as needed
  }, [form]);

  // Effect to reset booking details when activeTab changes
  // useEffect(() => {
  //   // dispatch(resetBookingDetails());
  //   // Optionally reset any local state if necessary
  //   setPickupLocation("");
  //   setDropLocation("");
  //   setPickupLatLng(null);
  //   setDropLatLng(null);
  //   setDistance(null);
  //   setDateTime(null);
  //   setNumPeople(1);
  //   setIsRoundTrip(false);
  //   setreturnDateTime(null);
  //   setPackageSelection(null);
  // }, [activeTab, dispatch]);

  // Use useSelector to get the dateTime value from the booking slice
  const reduxDateTime = useSelector((state) => state.booking.dateTime);

  // Update local state when Redux state changes
  // useEffect(() => {
  //   setDateTimeString(formatLocalDateTime(reduxDateTime));
  // }, [reduxDateTime]);

  const handleRoundTripChange = (e) => {
    setIsRoundTrip(e.target.value === "Yes");
  };

  const setDateTimeVal = (value) => {
    const newDateTime = new Date(value);
    // setDateTime(newDateTime);
    dispatch(setBookingDetails({ dateTime: newDateTime }));
  };
  const setReturnDateTimeVal = (value) => {
    const newDateTime = new Date(value);
    // setreturnDateTime(newDateTime);
    dispatch(setBookingDetails({ returnDateTime: newDateTime }));
  };

  // Function to format date to local time zone in YYYY-MM-DDTHH:MM format
  const formatLocalDateTime = (date) => {
    const offset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const localISOTime = new Date(date - offset).toISOString().slice(0, 16);
    return localISOTime;
  };

  // Get current date-time in the correct format for the min attribute
  const currentDateTime = new Date();

  const handleSelect = async (
    address,
    setLocation,
    locationField,
    latLngField
  ) => {
    setLocation(address); // Update local state

    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      if (locationField === "pickupLocation") {
        setPickupLatLng(latLng);
      } else {
        setDropLatLng(latLng);
      }
    } catch (error) {
      console.error("Error getting geocode for address", error);
    }
  };

  // Dispatch the form state update when any field changes
  const updateFormState = (field, value) => {
    dispatch(setFormDetails({ ...form, [field]: value }));
  };

  const handleSubmit = async () => {
    setisLoading(true);
    if (!pickupLatLng) {
      alert("Please select the pickup location");
      setisLoading(false);
      return;
    }
    console.log("Date time is", dateTime);
    if (!dateTime) {
      alert("Please select date and time");
      setisLoading(false);
      return;
    }

    try {
      let response = null;
      if (dropLocation !== "") {
        response = await axios.post(`${BE_URL}/api/get_vehicles/`, {
          Origin_lat: pickupLatLng.lat,
          Origin_lng: pickupLatLng.lng,
          Dest_lat: dropLatLng.lat,
          Dest_lng: dropLatLng.lng,
          numPeople: Number(numPeople),
          roundtrip: isRoundTrip,
          returnDateTime,
        });
      } else {
        response = await axios.post(`${BE_URL}/api/get_vehicles_hourly/`, {
          Origin_lat: pickupLatLng.lat,
          Origin_lng: pickupLatLng.lng,
          numPeople: Number(numPeople),
        });
      }

      const data = response.data;

      const distance = data.distance ? data.distance : 0;

      dispatch(
        setBookingDetails({
          pickupLocation,
          dropLocation,
          pickupLatLng,
          dropLatLng,
          distance,
          dateTime,
          numPeople,
          roundtrip: isRoundTrip,
          activeTab,
          returnDateTime,
          packageSelected: packageSelection,
        })
      );

      // Dispatch the vehicle state update when any field changes
      dispatch(
        setVehicleDetails({ vehicles: data.vehicles, distance: data.distance })
      );
      setisLoading(false);
      navigate("/listing");
    } catch (error) {
      setisLoading(false);
      console.error("Axios error", error);
      alert("Something went wrong, please try again");
    }
  };

  const renderFormFields = () => {
    switch (activeTab) {
      case "Outstation":
        return (
          <>
            <div className="bookingbox-form">
              <div className="bookingbox-form-selector">
                <LoadScript
                  googleMapsApiKey="AIzaSyBV3jNQZL6GxQ9YpUNY-OjVSn0M76LfKK0"
                  libraries={libraries}
                >
                  <PlacesAutocomplete
                    value={pickupLocation}
                    onChange={(address) => {
                      setPickupLocation(address); // Update local state
                      dispatch(
                        setFormDetails({ ...form, pickupLocation: address })
                      ); // Update Redux store
                    }}
                    onSelect={(address) =>
                      handleSelect(
                        address,
                        setPickupLocation,
                        "pickupLocation",
                        "pickupLatLng"
                      )
                    }
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="formfield">
                        <label htmlFor="input">Pickup Location</label>
                        <input
                          className="bookingbox-form-selector-input"
                          {...getInputProps({
                            placeholder: "Pick-up Location",
                            label: "Pick location",
                          })}
                        />
                        <div className="bookingbox-form-selector-relative">
                          {loading ? <div>Loading...</div> : null}
                          {suggestions ? (
                            <div className="bookingbox-form-selector-suggestionBox">
                              {suggestions.map((suggestion) => (
                                <div
                                  className="bookingbox-form-selector-suggestionBox-suggestion"
                                  {...getSuggestionItemProps(suggestion)}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                  <PlacesAutocomplete
                    value={dropLocation}
                    onChange={(address) => {
                      setDropLocation(address); // Update local state
                      dispatch(
                        setFormDetails({ ...form, dropLocation: address })
                      ); // Update Redux store
                    }}
                    onSelect={(address) =>
                      handleSelect(
                        address,
                        setDropLocation,
                        "dropLocation",
                        "dropLatLng"
                      )
                    }
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="formfield">
                        <label htmlFor="input">Drop Location</label>
                        <input
                          className="bookingbox-form-selector-input"
                          {...getInputProps({ placeholder: "Drop Location" })}
                        />
                        <div className="bookingbox-form-selector-relative">
                          {loading ? <div>Loading...</div> : null}
                          <div className="bookingbox-form-selector-suggestionBox">
                            {suggestions.map((suggestion) => (
                              <div
                                className="bookingbox-form-selector-suggestionBox-suggestion"
                                {...getSuggestionItemProps(suggestion)}
                              >
                                {suggestion.description}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                  <div className="formfield">
                    <label htmlFor="input">Pickup Date and Time</label>
                    <input
                      type="datetime-local"
                      className="bookingbox-form-selector-input-date"
                      name="journeytime"
                      value={dateTime}
                      min={currentDateTime}
                      onChange={(e) => setDateTime(e.target.value)}
                    ></input>
                  </div>

                  <div className="formfield">
                    <label htmlFor="input">Number of People</label>
                    <input
                      className="bookingbox-form-selector-input"
                      type="number"
                      min="1"
                      value={numPeople || "Num of people"}
                      placeholder="Number of people"
                      onChange={(e) => setNumPeople(e.target.value)}
                    />
                  </div>

                  <div className="formfield">
                    <label htmlFor="round">Round Trip?</label>
                    <select
                      id="round"
                      name="round"
                      className="bookingbox-form-selector-input"
                      onChange={handleRoundTripChange} // Added onChange handler
                      value={isRoundTrip ? "Yes" : "No"}
                    >
                      <option value="Yes">Yes</option>
                      <option default value="No">
                        No
                      </option>
                    </select>
                  </div>

                  {/* Conditional rendering for date and time input */}
                  {isRoundTrip && (
                    <div className="formfield">
                      <label htmlFor="input">Return Date</label>
                      <input
                        type="date"
                        className="bookingbox-form-selector-input-date"
                        name="journeytime"
                        value={returnDateTime}
                        min={currentDateTime}
                        onChange={(e) => setreturnDateTime(e.target.value)}
                      ></input>
                    </div>
                  )}

                  {/* {distance && <p>Distance: {distance.toFixed(2)} km</p>} */}
                </LoadScript>
              </div>
            </div>
          </>
        );
      case "Hourly Rental":
        return (
          <>
            <div className="bookingbox-form">
              <div className="bookingbox-form-selector">
                <LoadScript
                  googleMapsApiKey="YOUR_API_KEY"
                  libraries={libraries}
                >
                  {/* Pickup Location */}
                  <PlacesAutocomplete
                    value={pickupLocation}
                    onChange={setPickupLocation}
                    onSelect={(address) =>
                      handleSelect(
                        address,
                        setPickupLocation,
                        "pickupLocation",
                        "pickupLatLng"
                      )
                    }
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="formfield">
                        <label htmlFor="input">Pickup Location</label>
                        <input
                          className="bookingbox-form-selector-input"
                          {...getInputProps({
                            placeholder: "Pick-up Location",
                          })}
                        />
                        <div className="bookingbox-form-selector-relative">
                          {loading ? <div>Loading...</div> : null}
                          {suggestions ? (
                            <div className="bookingbox-form-selector-suggestionBox">
                              {suggestions.map((suggestion) => (
                                <div
                                  className="bookingbox-form-selector-suggestionBox-suggestion"
                                  {...getSuggestionItemProps(suggestion)}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                  {/* Drop Location here, if needed */}

                  {/* Date and Time Selection */}
                  <div className="formfield">
                    <label htmlFor="input">Pickup Date and Time</label>
                    <input
                      type="datetime-local"
                      className="bookingbox-form-selector-input-date"
                      name="journeytime"
                      value={dateTime}
                      min={currentDateTime}
                      onChange={(e) => setDateTime(e.target.value)}
                    ></input>
                  </div>

                  {/* Package Selection */}
                  <div className="formfield">
                    <label htmlFor="package">Package</label>
                    <select
                      id="package"
                      className="bookingbox-form-selector-input"
                      value={packageSelection}
                      onChange={(e) => setPackageSelection(e.target.value)}
                    >
                      <option value={4}>4 hours 40 kms</option>
                      <option value={6}>6 hours 60 kms</option>
                      <option value={8}>8 hours 80 kms</option>
                      <option value={12}>12 hours 120 kms</option>
                      <option value={24}>24 hours 300 kms</option>
                    </select>
                  </div>
                </LoadScript>
              </div>
            </div>
          </>
        );

      case "Airport Transfer":
        return (
          <>
            <div className="bookingbox-form">
              <div className="bookingbox-form-selector">
                <LoadScript
                  googleMapsApiKey="YOUR_API_KEY"
                  libraries={libraries}
                >
                  {/* Airport Transfer Direction Selection */}
                  <div className="formfield">
                    <label htmlFor="transferDirection">
                      Transfer Direction
                    </label>
                    <select
                      id="transferDirection"
                      className="bookingbox-form-selector-input"
                      value={airportTransferDirection}
                      onChange={(e) =>
                        setAirportTransferDirection(e.target.value)
                      }
                    >
                      <option value="to">To Airport</option>
                      <option value="from">From Airport</option>
                    </select>
                  </div>

                  {airportTransferDirection === "to" && (
                    <>
                      {/* Pickup Location for 'To Airport' transfer */}
                      <PlacesAutocomplete
                        value={pickupLocation}
                        onChange={setPickupLocation}
                        onSelect={(address) =>
                          handleSelect(
                            address,
                            setPickupLocation,
                            "pickupLocation",
                            "pickupLatLng"
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="formfield">
                            <label htmlFor="input">Pickup Location</label>
                            <input
                              className="bookingbox-form-selector-input"
                              {...getInputProps({
                                placeholder: "Pick-up Location",
                              })}
                            />
                            <div className="bookingbox-form-selector-relative">
                              {loading ? <div>Loading...</div> : null}
                              {suggestions ? (
                                <div className="bookingbox-form-selector-suggestionBox">
                                  {suggestions.map((suggestion) => (
                                    <div
                                      className="bookingbox-form-selector-suggestionBox-suggestion"
                                      {...getSuggestionItemProps(suggestion)}
                                    >
                                      {suggestion.description}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                      {/* Airport selection for 'To Airport' transfer */}
                      <PlacesAutocomplete
                        value={dropLocation}
                        onChange={setDropLocation}
                        onSelect={(address) =>
                          handleSelect(
                            address,
                            setDropLocation,
                            "dropLocation",
                            "dropLatLng"
                          )
                        }
                        searchOptions={{ types: ["airport"] }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="formfield">
                            <label htmlFor="input">Drop Location</label>
                            <input
                              className="bookingbox-form-selector-input"
                              {...getInputProps({
                                placeholder: "Select Airport",
                              })}
                            />
                            <div className="bookingbox-form-selector-relative">
                              {loading ? <div>Loading...</div> : null}
                              <div className="bookingbox-form-selector-suggestionBox">
                                {suggestions.map((suggestion) => (
                                  <div
                                    className="bookingbox-form-selector-suggestionBox-suggestion"
                                    {...getSuggestionItemProps(suggestion)}
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </>
                  )}

                  {airportTransferDirection === "from" && (
                    <>
                      {/* Airport selection for 'From Airport' transfer */}
                      <PlacesAutocomplete
                        value={pickupLocation}
                        onChange={setPickupLocation}
                        onSelect={(address) =>
                          handleSelect(
                            address,
                            setPickupLocation,
                            "pickupLocation",
                            "pickupLatLng"
                          )
                        }
                        searchOptions={{ types: ["airport"] }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="formfield">
                            <input
                              {...getInputProps({
                                placeholder: "Select Airport",
                                className: "bookingbox-form-selector-input",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  {...getSuggestionItemProps(suggestion)}
                                  className="suggestion-item"
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>

                      {/* Drop Location for 'From Airport' transfer */}
                      <PlacesAutocomplete
                        value={dropLocation}
                        onChange={setDropLocation}
                        onSelect={(address) =>
                          handleSelect(
                            address,
                            setDropLocation,
                            "dropLocation",
                            "dropLatLng"
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="formfield">
                            <input
                              {...getInputProps({
                                placeholder: "Drop Location",
                                className: "bookingbox-form-selector-input",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  {...getSuggestionItemProps(suggestion)}
                                  className="suggestion-item"
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </>
                  )}

                  <div className="formfield">
                    <label htmlFor="input">Pickup Date and Time</label>
                    <input
                      type="datetime-local"
                      className="bookingbox-form-selector-input-date"
                      name="journeytime"
                      value={dateTime}
                      min={currentDateTime}
                      onChange={(e) => setDateTime(e.target.value)}
                    ></input>
                  </div>
                </LoadScript>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="bookingbox">
      {isLoading && <Loader />}
      <div className="bookingbox-nav">
        <div
          className={`bookingbox-nav-item ${
            activeTab === "Outstation" ? "active" : ""
          }`}
          onClick={() => setActiveTab("Outstation")}
        >
          Outstation
        </div>
        <div
          className={`bookingbox-nav-item ${
            activeTab === "Hourly Rental" ? "active" : ""
          }`}
          onClick={() => setActiveTab("Hourly Rental")}
        >
          Hourly Rental
        </div>
        <div
          className={`bookingbox-nav-item ${
            activeTab === "Airport Transfer" ? "active" : ""
          }`}
          onClick={() => setActiveTab("Airport Transfer")}
        >
          Airport Transfer
        </div>
      </div>
      {renderFormFields()}
      <div className="bookingbox-button">
        <Button type={`primary`} text="Submit " onClick={handleSubmit} />
      </div>
      <div className="bookingbox-enquiry">
        FOR ENQUIRES,{" "}
        <Link
          target="_blank"
          to={`tel:+917061014622`}
          className="bookingbox-enquiry-link"
        >
          CALL US AT +91 70610 14622
        </Link>
      </div>
    </div>
  );
}

export default BookingBox;
