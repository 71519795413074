import ContactCard from "../../components/ContactCard/ContactCard";
import "./Connect.scss";

import React from "react";

import { ReactComponent as Message } from "../../assets/images/message.svg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

function Connect() {
  const navigate = useNavigate();
  const contactNavigate = () => {
    navigate("/contact");
  };
  return (
    <div className="connect">
      <div className="connect-title Monoton ">Let's connect</div>
      <div className="connect-content">
        <div className="connect-content-letter">
          <ContactCard />
        </div>
        <div className="connect-content-envelope">
          <Message />
          <div className="connect-content-envelope-title">
            Give wings to your travel plans. Click below to get in touch.
          </div>
          <Button type="arrow" text="Get in Touch" onClick={contactNavigate} />
        </div>
      </div>
    </div>
  );
}

export default Connect;
