import "./StepCard.scss";

import React from "react";

function StepCard({ id, info, image }) {
  return (
    <div className="stepcard">
      <div className="stepcard-id">{id}</div>
      <div className="stepcard-contents">
        <div className="stepcard-contents-info">{info}</div>
        <div className="stepcard-contents-image">{image}</div>
      </div>
    </div>
  );
}

export default StepCard;
