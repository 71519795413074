import "./FeedbackCard.scss";

import React from "react";

function FeedbackCard({ feedback, name }) {
  return (
    <div className="feedbackCard">
      <div className="feedbackCard-stars">
        &#10029;&#10029;&#10029;&#10029;&#10029;
      </div>
      <div className="feedbackCard-feedback">{feedback}</div>
      <div className="feedbackCard-name">{name}</div>
    </div>
  );
}

export default FeedbackCard;
