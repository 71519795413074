import "./Fleet.scss";

import React from "react";
import FleetCard from "../../components/FleetCard/FleetCard";

import Slider from "react-slick";

const data = [
  {
    id: 1,
    name: "Sedan",
    image:
      "https://images.91wheels.com/assets/b_images/main/models/profile/profile1701779862.jpg",
    desc: "With 2 row seating, its ideal for 3 to 4 people. Economy-Etios/Dzire | Premium - City | Royal-Mercedez, AUDI, BMW for Weddings & Corporates.",
    seat: "3 To 4 Seater",
    fare: "Starts from Rs. 12/Km",
  },
  {
    id: 2,
    name: "SUV",
    image:
      "https://imgd-ct.aeplcdn.com/370x231/n/cw/ec/47051/compass-exterior-right-front-three-quarter-74.jpeg?isig=0&q=80",
    desc: "With 3 row seating, its ideal for 5 to 7 people. Economy-innova, Ertiga, Marazzo | Premium-Crysta, Carnival | Royal- Fortuner, Mercedez, AUDI, BMW for Weddings & Corporates.",
    seat: "5 To 7 Seater",
    fare: "Starts from Rs. 15/Km",
  },
  {
    id: 3,
    name: "Traveller",
    image:
      "https://busimg.cardekho.com/p/630x420/in/force/traveller-3050/force-traveller-3050.jpg",
    desc: "Comfortable Travel, Companion for Medium size Groups. This comes in following Configurations- 1-Economy (Basic) 2- Premium (PKN/Alius) 3- Royal (1x1 VIP Maharaja Seats)",
    seat: "12 To 26 Seater",
    fare: "Starts from Rs. 17/Km",
  },
  {
    id: 4,
    name: "Bus",
    image:
      "https://bl-i.thgim.com/public/incoming/6qrxkn/article65720743.ece/alternates/LANDSCAPE_1200/262A9040.jpg",
    desc: "Full size Luxury Buses are the best Travel option for Large Groups to keep chit-chatting along the panoramic views of highway. Premium Bus-Tata/Ashok Leyland, Royal Bus- Benz, Volvo.",
    seat: "27 To 50 Seater",
    fare: "Starts from Rs. 35/Km",
  },
];

function Fleet() {
  // Settings for the slider
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="fleet section section-right">
      <div className="fleet-border section-border-right"></div>
      <div className="fleet-title section-title">Fleet</div>
      <div className="fleet-contents">
        <Slider {...settings}>
          {data.map((item) => (
            <div key={item.id}>
              <FleetCard {...item} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Fleet;
