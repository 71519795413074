import "./Partners.scss";
import partners from "../../assets/images/partners.png";

import React from "react";

function Partners() {
  return (
    <div className="partners section section-left">
      <div className="partners-border section-border-left"></div>
      <div className="partners-title section-title">Our Partners</div>
      <div className="partners-contents">
        <img src={partners} alt="" />
      </div>
    </div>
  );
}

export default Partners;
