import Button from "../Button/Button";
// import InputBox from "../InputBox/InputBox";
import Loader from "../Loader/Loader";
import "./ContactCard.scss";
import axios from "axios";

import React, { useState } from "react";

function ContactCard() {
  const [isLoading, setisLoading] = useState(false);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setmessage] = useState("");

  const handleSend = async (e) => {
    setisLoading(true);
    e.preventDefault();
    try {
      const formData = {
        full_name: name,
        contact_info: mobile,
        message: message,
      };
      const response = await axios.post(
        "https://api.buswing.com/api/submit-contact-card/",
        formData
      );
      console.log(response.data);
      alert("Thanks! We will get in touch with you shortly");
      setName("");
      setmessage("");
      setMobile("");
      setisLoading(false);

      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error submitting contact card:", error);
      setisLoading(false);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="contactCard">
      {isLoading && <Loader />}
      <form onSubmit={handleSend}>
        <input
          className="input-box"
          name="full_name"
          type="text"
          placeholder="Full Name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="input-box"
          name="contact_info"
          type="text"
          placeholder="Email/Mobile"
          onChange={(e) => setMobile(e.target.value)}
        />
        <input
          className="text-area"
          name="message"
          type="textarea"
          placeholder="Your Message"
          onChange={(e) => setmessage(e.target.value)}
        />{" "}
        <br />
        <Button type="primary" text="Send Message" />
      </form>
    </div>
  );
}

export default ContactCard;
