import "./About.scss";

import React from "react";
import aboutbg from "../../assets/images/aboutbg.jpg";
import agency from "../../assets/images/agency.jpg";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import FAQComponent from "../../components/FAQComponent/FAQComponent";

const difference = [
  {
    id: 1,
    title: "Diverse Fleet",
    data: "From compact cars to luxury SUVs, we have a vehicle to suit every need.",
  },
  {
    id: 2,
    title: "Safety First",
    data: "All our vehicles undergo rigorous checks and are maintained by certified professionals.",
  },
  {
    id: 3,
    title: "Transparent Pricing",
    data: "No hidden fees. What you see is what you pay.",
  },
  {
    id: 4,
    title: "Occassional Vehicles",
    data: "Are you planning a wedding or a celebration? We have a fleet of vehicles for your guests.",
  },
  {
    id: 5,
    title: "Professional Drivers",
    data: "We have all well-trained and professional who are ready to drive you and assist you always.",
  },
];

const About = () => {
  const navigate = useNavigate();

  const contactClicked = () => {
    navigate("/contact");
  };

  return (
    <div className="about">
      <div
        className="about-hero"
        style={{ backgroundImage: `url(${aboutbg})` }}
      >
        <div className="about-hero-title">About Us</div>
        <div className="about-hero-sep"></div>
        <div className="about-hero-name">BUSWING</div>
        <div className="about-hero-subtext">Experiences that last forever.</div>
      </div>

      <div className="about-us">
        {/* <div className="about-us-sep"></div> */}

        <div className="about-us-content">
          <div className="about-us-content-left">
            <img src={agency} alt="Buswing Agency" />
          </div>

          <div className="about-us-content-right">
            <div className="about-us-content-right-content">
              <div className="about-us-content-right-content-heading">
                Beyond Just a Ride - An Experience!
              </div>
              <div className="about-us-content-right-content-info">
                Established in 2023, Buswing began with a dream to make vehicle
                booking seamless, affordable, and efficient. From humble
                beginnings in the financial capital of India, Mumbai, we've now
                expanded our services to over 5+ cities throughout India,
                serving hundreds of satisfied customers daily.
              </div>
              <div className="about-us-content-right-content-button">
                <Button text={`Contact Us`} onClick={contactClicked} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about-result-sep"></div> */}
      </div>

      <div className="about-philosophy">
        <div className="about-philosophy-heading">Our Mission</div>
        <div className="about-philosophy-content">
          To provide seamless, reliable, and efficient vehicle booking
          experiences, ensuring every journey is safe, comfortable, and
          punctual. We commit to empowering our customers with diverse
          transportation choices, backed by professional drivers and
          round-the-clock support.
        </div>
      </div>

      <div className="about-difference">
        <div className="about-difference-heading">What makes us different?</div>
        <div className="about-difference-content">
          {difference.map((value) => (
            <DifferenceCard
              key={value.id}
              id={value.id}
              data={value.data}
              title={value.title}
            />
          ))}
        </div>
      </div>

      <div className="about-faqs">
        <div className="about-faqs-heading">Frequently Asked Questions</div>
        <div className="about-faqs-content">
          <FAQComponent />
        </div>
      </div>
    </div>
  );
};

export default About;

const DifferenceCard = ({ id, title, data }) => {
  return (
    // <div
    //   className={`${id === 2 ? "differenceCard-movedown" : "differenceCard"}`}
    // >
    <div className="differenceCard">
      <div className="differenceCard-id">{id}</div>
      <div className="differenceCard-title">{title}</div>
      <div className="differenceCard-data">{data}</div>
    </div>
  );
};
