import { createSlice } from "@reduxjs/toolkit";

export const formSlice = createSlice({
  name: "form",
  initialState: {
    activeTab: "Outstation",
    pickupLocation: "",
    dropLocation: "",
    pickupLatLng: null,
    dropLatLng: null,
    distance: null,
    dateTime: new Date(),
    numPeople: 1,
  },
  reducers: {
    setFormDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setFormDetails } = formSlice.actions;

export default formSlice.reducer;
