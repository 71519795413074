import React, { useState } from "react";
import axios from "axios";
import "./ChangePassword.scss"; // Ensure this path is correct

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const refreshAndRetry = async (refreshToken) => {
    try {
      const refreshResponse = await axios.post(
        "http://127.0.0.1:8000/api/token/refresh/",
        { refresh: refreshToken }
      );
      localStorage.setItem("authTokens", JSON.stringify(refreshResponse.data));
      return await changePassword(refreshResponse.data.access);
    } catch (error) {
      console.error("Failed to refresh token", error);
      setIsError(true);
      setMessage("Session expired. Please log in again.");
      // Optionally navigate to login page or handle as per your flow
    }
  };

  const changePassword = async (accessToken) => {
    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/api/change-password/",
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const authTokens = JSON.parse(localStorage.getItem("authTokens"));

    if (!authTokens) {
      setMessage("Not authenticated. Please log in.");
      setIsError(true);
      return;
    }

    try {
      const data = await changePassword(authTokens.access);
      setMessage(data.success);
      setIsError(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshAndRetry(authTokens.refresh);
      } else {
        setMessage(error.response?.data?.error || "An error occurred.");
        setIsError(true);
      }
    }
  };

  return (
    <div className="change-password-container">
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Old Password"
          required
        />
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          required
        />
        <button type="submit">Change Password</button>
      </form>
      {message && (
        <p className={isError ? "error-message" : "success-message"}>
          {message}
        </p>
      )}
    </div>
  );
}

export default ChangePassword;
