import "./Customers.scss";
import customersbg from "../../assets/images/customersbg.png";

import React from "react";
import FeedbackCard from "../../components/FeedbackCard/FeedbackCard";
import Slider from "react-slick";

const customersfeedback = [
  {
    id: 1,
    name: "Abhishek Mathur",
    feedback:
      "One of the best tours and travels agency in India. They provide very neat and clean cars with professional drivers.",
    stars: 5,
  },
  {
    id: 2,
    name: "Navneet Singh",
    feedback:
      "One of the best tours and travels agency in India. They provide very neat and clean cars with professional drivers.",
    stars: 5,
  },
];

function Customers() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="customers section"
      style={{ backgroundImage: `url(${customersbg})` }}
    >
      <div className="customers-title section-title">
        What our Customers say
      </div>
      <div className="customers-contents">
        <Slider {...settings}>
          {customersfeedback.map((item) => (
            <FeedbackCard key={item.id} {...item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Customers;
