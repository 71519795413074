import "./Button.scss";

import React from "react";

function Button({ text, onClick, type }) {
  return (
    <button className={`button button-${type}`} onClick={onClick}>
      {text} {type === "arrow" ? <span className="button-arrow">→</span> : null}
    </button>
  );
}

export default Button;
