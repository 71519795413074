import OfferCard from "../../components/OfferCard/OfferCard";
import "./Offers.scss";

import React from "react";
import Slider from "react-slick";

const offers = [
  {
    id: 1,
    name: "WELCOME100",
    content: "Get Rs 100/- off on your first booking.",
  },
  {
    id: 2,
    name: "FLYBUS200",
    content: "Book a bus and get Rs 200/- off.",
  },
  {
    id: 3,
    name: "SEDAN50",
    content: "Book a sedan and get Rs 50/- off.",
  },
];

function Offers() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="offers section section-left">
      <div className="offers-border section-border-left"></div>
      <div className="offers-title section-title">Offers</div>
      <div className="offers-contents">
        <Slider {...settings}>
          {offers.map((offer) => (
            <OfferCard
              key={offer.id}
              name={offer.name}
              content={offer.content}
              id={offer.id}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Offers;
