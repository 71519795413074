import "./VehicleList.scss";

import React from "react";
import VehicleCard from "../VehicleCard/VehicleCard";
import BookingBox from "../BookingBox/BookingBox";
import hero from "../../assets/images/hero.png";
import { useSelector } from "react-redux";

function VehicleList({ setVehicleData }) {
  // const formDetails = useSelector((state) => state.booking);
  const vehiclesdata = useSelector((state) => state.vehicle);

  const vehicles = vehiclesdata ? vehiclesdata.vehicles : [];
  console.log("Vehicles data is: ", vehicles);

  return (
    <div className="vl section">
      <div className="vl-hero">
        <div className="vl-hero-left">
          <BookingBox setVehicleData={setVehicleData} />
        </div>
        <div className="vl-hero-right">
          <img
            src={hero}
            alt="Buswing traval"
            className="vl-hero-right-image"
          />
        </div>
      </div>
      {vehicles ? (
        <>
          <div className="vl-num">{vehicles.length} vehicles found.</div>
          <div className="vl-list">
            {vehicles.map((vehicle) => (
              <VehicleCard vehicle={vehicle} distance={vehiclesdata.distance} />
            ))}
          </div>
        </>
      ) : (
        "NO vehicles found."
      )}
    </div>
  );
}

export default VehicleList;
