import "./OfferCard.scss";
import { ReactComponent as OfferIcon } from "../../assets/images/offer.svg";

import React from "react";

function OfferCard({ name, content, id }) {
  return (
    <div className={`offerCard offerCard-${id}`}>
      <div className="offerCard-offer">{name}</div>
      <div className="offerCard-contents">
        <div className="offerCard-contents-desc">
          <div className="offerCard-contents-desc-title">{content}</div>
          {/* <div className="offerCard-contents-desc-cta">
            Know More{" "}
            <span className="offerCard-contents-desc-subtitle-arrow">
              &#8594;
            </span>{" "}
          </div> */}
        </div>
        <div className="offerCard-contents-icon">
          <OfferIcon />
        </div>
      </div>
    </div>
  );
}

export default OfferCard;
