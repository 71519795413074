import Button from "../Button/Button";
import "./FleetCard.scss";
import { useNavigate } from "react-router-dom";

import React from "react";

function FleetCard({ image, name, desc, seat, fare }) {
  const navigate = useNavigate();
  return (
    <div className="fleetCard">
      <div className="fleetCard-image">
        <img src={image} alt={` ${name}`} />
      </div>
      <div className="fleetCard-contents">
        <div className="fleetCard-contents-name">{name}</div>
        <div className="fleetCard-contents-desc">{desc}</div>
        <div className="fleetCard-contents-sep"></div>

        <div className="fleetCard-contents-details">
          <div className="fleetCard-contents-details-seat">Seat - {seat}</div>
          <div className="fleetCard-contents-details-fare">Fare - {fare}</div>
        </div>
        <div className="fleetCard-contents-sep"></div>

        <div className="fleetCard-contents-button">
          <Button
            text={`Book Now`}
            type={`primary`}
            onClick={() => window.scrollTo(0, 0)}
          />
        </div>
      </div>
    </div>
  );
}

export default FleetCard;
