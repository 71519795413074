import "./Hero.scss";

import React, { useState } from "react";

import hero from "../../assets/images/hero.png";
import callback from "../../assets/images/callback.jpg";
import BookingBox from "../BookingBox/BookingBox";
import Button from "../Button/Button";
import axios from "axios";
import InputBox from "../InputBox/InputBox";
import Loader from "../Loader/Loader";

function Hero({ setVehicleData }) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const handleSend = async () => {
    if (mobile.length !== 10) {
      alert("Number must be of 10 digits");
      return;
    }
    setisLoading(true);
    const formData = {
      full_name: name,
      contact_info: mobile,
      message: "Requesting callback.",
    };

    try {
      const response = await axios.post(
        "https://api.buswing.com/api/submit-contact-card/",
        // "http://127.0.0.1:8000/api/submit-contact-card/",
        formData
      );

      setName("");
      setMobile("");
      alert("Thanks! You will receive a callback shortly!");
      setisLoading(false);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error submitting contact card:", error);
      alert("Something went wrong, please try again later.");
      setisLoading(false);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="hero">
      {isLoading && <Loader />}
      <div className="hero-title">Give wings to your travel plans.</div>
      <div className="hero-subtitle">
        Book Cars, tempo travellers and buses for your travel plans.
      </div>

      <div className="hero-contents">
        <div className="hero-contents-left">
          <BookingBox setVehicleData={setVehicleData} />
        </div>
        <div className="hero-contents-right">
          <img
            src={hero}
            alt="Buswing traval"
            className="hero-contents-right-image"
          />
        </div>
      </div>
      <div className="hero-bottom">
        <div className="hero-bottom-contents">
          <div className="hero-bottom-contents-icon">
            <img src={callback} alt="" />
          </div>
          <div className="hero-bottom-contents-title">
            <div className="hero-bottom-contents-title-text">
              Need Vehicles? We will help.
            </div>
            <div className="hero-bottom-contents-title-subtext">
              Request a call back
            </div>
          </div>
        </div>
        <div className="hero-bottom-form">
          <input
            className="input-box"
            name="full_name"
            type="text"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="input-box"
            name="contact_info"
            type="text"
            placeholder="Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <Button
            text="Request a call back"
            type="primary"
            onClick={handleSend}
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
