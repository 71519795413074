import "./Auth.scss";

import React, { useEffect, useState } from "react";
import compass from "../../assets/images/compass.jpg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../state/auth/authSlice";

import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import Loader from "../../components/Loader/Loader";

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  // const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("authTokens");

    if (userData) {
      console.log("User data is ", userData.access);

      const obj = jwtDecode(JSON.parse(userData).access);
      console.log(obj);
      const { name, user_id } = obj;

      const user = {
        name: name,
        user_id: user_id,
      };

      dispatch(setUser(user));
      navigate("/");
    }
  }, []);

  const handleToggleForm = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="auth">
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <SignupComplete
          toggle={handleToggleForm}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      <div className="auth-left">
        {isLogin ? (
          <Login toggle={handleToggleForm} />
        ) : (
          <Signup toggle={handleToggleForm} setIsModalOpen={setIsModalOpen} />
        )}
      </div>
      <div className="auth-right">
        <div className="auth-right-title">
          Giving wings to your travel plans.{" "}
        </div>
        <div className="auth-right-text">
          Plan your next travel with us for a hassle free and enjoyable ride. We
          ensure your world class travel experience is our highly maintained
          vehicles and well trained professional drivers.
        </div>
        <div className="auth-right-image">
          <img src={compass} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Auth;

const Login = ({ toggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    setisLoading(true);
    const data = {
      username: email, // use email as the username for simplicity
      password: password,
    };

    // const BE_URL = `http://127.0.0.1:8000/api/${isLogin ? "login" : "signup"}/`;
    const BE_URL = `https://api.buswing.com/api/token/`;

    axios
      .post(BE_URL, data, { withCredentials: true })
      .then((response) => {
        // Handle successful login/signup
        console.log(response.data.access);
        localStorage.setItem("authTokens", JSON.stringify(response.data));

        const obj = jwtDecode(response.data.access);
        console.log(obj);
        const { name, user_id } = obj;

        const user = {
          name: name,
          user_id: user_id,
        };

        dispatch(setUser(user));
        setisLoading(false);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        setisLoading(false);
        alert("Error loggin in, please try again.");
      });
  };

  return (
    <div className="login">
      {isLoading && <Loader />}
      <div className="login-header">
        <div className="login-header-mark">Login</div>
        <div className="login-header-stat">
          Vehicles of your choice at your fingertips.
        </div>
      </div>

      <div className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button text="Login" type={`primary`} onClick={handleLogin} />
      </div>

      <div className="login-toggle">
        Not a user? <Button text={`Sign up`} type={`arrow`} onClick={toggle} />
      </div>
    </div>
  );
};

const Signup = ({ toggle, setIsModalOpen }) => {
  const [isLoading, setisLoading] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Check if phone number is exactly 10 digits
    if (!phone || phone.length !== 10 || !/^\d+$/.test(phone)) {
      alert("Invalid Phone Number!");
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    try {
      setisLoading(true);
      // setIsModalOpen(true); // You can uncomment this if you want to show modal on start of request
      const response = await axios.post("https://api.buswing.com/api/signup/", {
        // const response = await axios.post("http://127.0.0.1:8000/api/signup/", {
        name: firstname + " " + lastname,
        firstname,
        lastname,
        username: email,
        email,
        phone,
        password,
      });
      console.log(response.data);
      setisLoading(false);
      setIsModalOpen(true); // Assuming you want to open a modal on successful signup
      toggle(); // Assuming this is a function to control modal or redirection
    } catch (error) {
      setisLoading(false);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error
      ) {
        // Handle known error type explicitly
        setErrorMessage(error.response.data.error);
      } else {
        // Generic error handling
        setErrorMessage(
          "Error occurred during signup. Please try again later."
        );
      }
      alert(
        error.response.data.error ||
          "Something went wrong, please try again later."
      );
    }
  };

  return (
    <div className="signup">
      {isLoading && <Loader />}
      <div className="signup-header">
        <div className="signup-header-mark">Signup</div>
        <div className="signup-header-stat">
          Vehicles of your choice at your fingertips.
        </div>
      </div>
      <form className="signup-form" onSubmit={handleSignup}>
        <input
          type="name"
          value={firstname}
          onChange={(e) => setfirstname(e.target.value)}
          placeholder="First Name"
          required
        />
        <input
          type="name"
          value={lastname}
          onChange={(e) => setlastname(e.target.value)}
          placeholder="Last Name"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="number"
          value={phone}
          onChange={(e) => setphone(e.target.value)}
          placeholder="Phone"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          required
        />
        <Button type="primary" text={`Sign Up`} />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
      <div className="signup-toggle">
        Already a user?{" "}
        <Button text={`Login`} type={`arrow`} onClick={toggle} />
      </div>
    </div>
  );
};

const SignupComplete = ({ setIsModalOpen }) => {
  return (
    <div className="suc">
      <div className="suc-msg">
        Congratulations! and welcome onboard. Your account is created. Please
        login to continue.
      </div>
      <Button
        type={`primary`}
        text={`Login`}
        onClick={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};
