import Hero from "../../components/Hero/Hero";
import VehicleList from "../../components/VehicleList/VehicleList";
import Connect from "../../sections/Connect/Connect";
import Customers from "../../sections/Customers/Customers";
import Experience from "../../sections/Experience/Experience";
import Fleet from "../../sections/Fleet/Fleet";
import Offers from "../../sections/Offers/Offers";
import Partners from "../../sections/Partners/Partners";
import Services from "../../sections/Services/Services";
import Steps from "../../sections/Steps/Steps";

import phone from "../../assets/images/phone.png";
import whatsapp from "../../assets/images/whatsapp.png";

import "./Home.scss";

import React, { useState } from "react";
import { Link } from "react-router-dom";

function Home() {
  const [vehicleData, setVehicleData] = useState(null);

  return (
    <div className="Home">
      <Hero setVehicleData={setVehicleData} />
      {vehicleData ? <VehicleList data={vehicleData} /> : <></>}
      <Fleet />
      <Experience />
      <Offers />
      <Customers />
      <Steps />
      <Services />
      <Partners />
      <Connect />
      <div className="home-bottom">
        <Link
          target="_blank"
          to={`https://wa.me/7061014622?text=Hello,%20I%20am%20interested%20in%20booking%20vehicle%20from%20Buswing.`}
          className="home-bottom-button"
        >
          <img src={whatsapp} alt="Buswing" />
          <span className="home-bottom-button-text">Whatsapp</span>
        </Link>
        <Link
          target="_blank"
          to={`tel:+917061014622`}
          className="home-bottom-button"
        >
          <img src={phone} alt="Buswing" />
          <span className="home-bottom-button-text">Phone</span>
        </Link>
      </div>
    </div>
  );
}

export default Home;
