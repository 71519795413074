import React, { useState } from "react";
import "./FAQComponent.scss";

const FAQComponent = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const questionsAndAnswers = [
    {
      question: "How do I book a vehicle?",
      answer:
        "Simply search for your desired vehicle, select your dates, and complete the booking process online. You'll receive a confirmation email once your booking is complete.",
    },
    {
      question: "What are your safety protocols?",
      answer:
        "We prioritize the safety of our customers. All vehicles are sanitized thoroughly before each booking. Moreover, all our drivers undergo regular health checks.",
    },
    {
      question: "Can I modify my booking?",
      answer:
        "Yes, you can modify your booking up to 24 hours before the scheduled pick-up time. Reach out to us on +91 9988 998 990 to modify your booking.",
    },
    {
      question: "Do you provide vehicles for occassions/weddings?",
      answer:
        "Yes, we have a fleet of vehicles that you can book for any occassions, weddings or parties. React out to us for more info.",
    },
    {
      question: "Do you provide corporate pickup and drop?",
      answer:
        "Yes, Buswing is trusted by many companies for the pick up and drop services including but not limited to Google, SBI, etc.",
    },
    {
      question: "What happens in case of an accident or breakdown?",
      answer:
        "In case of any mishap, please contact our 24/7 helpline. We'll arrange for immediate assistance and ensure you're taken care of.",
    },
  ];

  return (
    <div className="faq">
      {questionsAndAnswers.map((item, index) => (
        <div key={index} className="faq-item">
          <div
            className="faq-item-question"
            onClick={() => handleToggle(index)}
          >
            {item.question}
            <span
              className={activeIndex === index ? "arrow up" : "arrow down"}
            ></span>
          </div>
          {activeIndex === index && (
            <div className="faq-answer">{item.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQComponent;
