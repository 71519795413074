import "./UserProfile.scss";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaMapMarkerAlt, FaClock, FaRupeeSign } from "react-icons/fa";

import user from "../../assets/images/user.jpg";
import sedan from "../../assets/images/sedan.jpg";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import { clearUser } from "../../state/auth/authSlice";
import { useDispatch } from "react-redux";

function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [iscancelOpen, setIscancelOpen] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState(null);

  const [islogoutOpen, setIslogoutOpen] = useState(false);

  //Cancelling the Booking.
  const handleCancelClick = (bookingId) => {
    setIscancelOpen(true);
    setSelectedBookingId(bookingId);
  };

  const confirmCancellation = () => {
    setIscancelOpen(false);
    cancelBooking(selectedBookingId).then(() => {
      // Handle post-cancellation logic, such as updating the list
    });
  };

  const cancelCancellation = () => {
    setIscancelOpen(false);
    setSelectedBookingId(null);
  };

  const fetchUserData = () => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens"));
    if (!authTokens) return;

    const access_token = authTokens.access;
    const refresh_token = authTokens.refresh;

    const conf = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    axios
      .get("https://api.buswing.com/api/user-profile/", conf) // Replace with your backend endpoint
      .then((response) => {
        console.log("User data from BE ", response.data.user);
        setUserData(response.data.user);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          console.log("Invalid token detected. Refreshing token...");

          // Refresh access token
          axios
            .post("https://api.buswing.com/api/token/refresh/", {
              refresh: refresh_token,
            }) // Replace with your backend endpoint
            .then((response) => {
              localStorage.setItem("authTokens", JSON.stringify(response.data));
              setUserData(response.data.user);

              // Retry get user profile with new token
              axios
                .get("https://api.buswing.com/api/user-profile/", {
                  headers: { Authorization: `Bearer ${response.data.access}` },
                })
                .then((response) => {
                  setUserData(response.data.user);
                })
                .catch((error) => {
                  console.error(
                    "Failed to fetch user profile with new token",
                    error
                  );
                });
            })
            .catch((error) => {
              console.error("Failed to refresh token", error);
              navigate("/login");
            });
        }
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const cancelBooking = async (bookingId) => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens"));

    if (!authTokens) {
      console.log("No auth tokens available. Redirecting to login.");
      // Redirect to login or handle unauthenticated state
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${authTokens.access}` },
    };

    try {
      const response = await axios.post(
        `https://api.buswing.com/api/cancel-booking/${bookingId}/`,
        {},
        config
      );
      console.log("Booking canceled successfully:", response.data);
      fetchUserData();
      // Update UI or state to reflect the booking cancellation
    } catch (error) {
      if (error.response) {
        // Check for token expiration
        if (error.response.status === 401) {
          console.log("Token expired. Attempting to refresh.");
          try {
            const refreshResponse = await axios.post(
              "https://api.buswing.com/api/token/refresh/",
              { refresh: authTokens.refresh }
            );
            localStorage.setItem(
              "authTokens",
              JSON.stringify(refreshResponse.data)
            );
            console.log("Token refreshed successfully.");
            // Retry canceling the booking with new token
            await cancelBooking(bookingId);
            fetchUserData();
          } catch (refreshError) {
            console.error("Failed to refresh token:", refreshError);
            // Handle failed token refresh (e.g., redirect to login)
            navigate("/");
          }
        } else {
          // Handle other API errors (e.g., booking not found, server error)
          console.error("Failed to cancel booking:", error.response.data);
          alert("Something went wrong, failed to cancel booking.");
        }
      } else {
        // Handle errors that aren't from the API (network issues, etc.)
        console.error("Error canceling booking:", error);
        alert("Something went wrong, failed to cancel booking.");
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authTokens");
    // Dispatch the action to clear user data
    dispatch(clearUser());

    navigate("/login");
  };

  return (
    <div className="profile">
      {/* Modal for changing password. */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ChangePassword />
      </Modal>

      {/* Modal for confirming cancel booking. */}
      <Modal isOpen={iscancelOpen} onClose={cancelCancellation}>
        <ConfirmationModal
          onConfirm={confirmCancellation}
          onCancel={cancelCancellation}
          ques="Are you sure you want to cancel the booking?"
        />
      </Modal>

      {/* Modal for confirming logout */}
      <Modal
        isOpen={islogoutOpen}
        onClose={() => {
          setIslogoutOpen(false);
        }}
      >
        <ConfirmationModal
          onConfirm={handleLogout}
          onCancel={() => {
            setIslogoutOpen(false);
          }}
          ques="Are you sure you want to logout?"
        />
      </Modal>
      <div className="profile-user">
        {/* User Info - Adjust according to your data structure */}
        <div className="profile-user-image">
          <img
            src={userData?.profilePic || user}
            alt="Profile"
            className="user-profile-pic"
          />
        </div>
        <div className="profile-user-details">
          <div className="profile-user-details-name">{userData?.name}</div>
          <div className="profile-user-details-email">
            Email: {userData?.email}
          </div>
          {/* <div className="profile-user-details-username">
            Username: {userData?.username}
          </div> */}
          <div className="profile-user-details-username">
            Phone: {userData?.phone}
          </div>
        </div>

        <div className="profile-user-buttons">
          <Button text="Change Password" type={`arrow`} onClick={openModal} />
          <Button
            text="Logout"
            type={`red`}
            onClick={() => {
              setIslogoutOpen(true);
            }}
          />
          {/* <Button text="Logout" type={`red`} onClick={handleLogout} /> */}
        </div>
      </div>

      <div className="profile-booking">
        <div className="profile-booking-heading">My Bookings</div>

        {userData && userData.bookings.length === 0 ? (
          <div className="profile-booking-nobooking">
            You have no bookings yet. <Link to="/">Book a vehicle now!</Link>
          </div>
        ) : (
          <div className="profile-booking-list">
            {userData?.bookings.map((booking) => (
              <BookingCard
                booking={booking}
                cancelBooking={handleCancelClick}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;

const BookingCard = ({ booking, cancelBooking }) => {
  function formatDateTime(dateTimeString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateTimeString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  console.log("booking data is ", booking);

  return (
    <div className="bc">
      <div className="bc-type">
        {booking.active_tab}
        {booking.roundtrip ? " - Round Trip" : ""}
      </div>
      <div className="bc-img">
        <img src={sedan} alt="Vehicle Image" />
      </div>

      <div className="bc-details">
        <div className="bc-details-name">{booking.vehicle}</div>
        <div className="bc-details-type">
          {booking.vtype} | {booking.vcapacity} seater
        </div>
        <div className="bc-details-from">
          <FaMapMarkerAlt className="bc-icon" /> FROM: {booking.pickup_location}
        </div>
        <div className="bc-details-to">
          <FaMapMarkerAlt className="bc-icon" /> TO: {booking.drop_location}
        </div>
        <div className="bc-details-date">
          <FaClock className="bc-icon" /> {formatDateTime(booking.date_time)}
        </div>
        {booking.returnDateTime ? (
          <div className="bc-details-date">
            <FaClock className="bc-icon" />
            Return : {formatDateTime(booking.returnDateTime)}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="bc-bottom">
        <div className="bc-bottom-price">
          <FaRupeeSign className="bc-icon" />
          {booking.price}
        </div>
        {booking.is_canceled ? (
          <div className="bc-bottom-canceled">Canceled</div>
        ) : (
          <Button
            text={`Cancel`}
            type={`red`}
            onClick={() => cancelBooking(booking.id)}
          />
        )}
        {/* <div className="bc-bottom-cancel"></div> */}
      </div>
    </div>
  );
};

const ConfirmationModal = ({ onConfirm, onCancel, ques }) => {
  return (
    <div className="cm">
      <div className="cm-ques">{ques}</div>
      <div className="cm-button">
        <Button text="Yes" onClick={onConfirm} type={`red`} />
        <Button text="No" onClick={onCancel} type={`arrow`} />
      </div>
    </div>
  );
};
